/**
Fixing bootstrap columns for safari
 */

/*banner fix*/
.bx-wrapper {
  border: none;
  box-shadow: none;
  margin-bottom: 10px;
}
.bx-wrapper .bx-pager {
  padding-top: 0;
  bottom: 20px;
}
.bx-wrapper .bx-controls-direction a {
  width: 33px;
  height: 50px;
  margin-top: -25px;
}
.bx-wrapper .bx-next,
.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-next:focus {
  background: url(../images/controls-next.png) no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.bx-wrapper .bx-prev,
.bx-wrapper .bx-prev:hover,
.bx-wrapper .bx-prev:focus {
  background: url(../images/controls-prev.png) no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.bx-wrapper .bx-pager.bx-default-pager a {
  background: #fff;
}
.default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus {
  background: #888;
}
.slider img,
.slider-mobile img {
  width: 100%;
}
.slider-mobile {
  display: none;
}
/*è¡¨é ­*/
header {
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 99999;
  box-shadow: 0px 1px 10px #333;
  width: 100vw;
}
.nwn {
  max-width: 1000px;
  margin: auto;
  position: relative;
}
.top-bar {
  position: relative;
  height: 65px;
}
.top-bar:after {
  content: "";
  clear: both;
  display: block !important;
}
.logo-new {
  height: 47px;
  overflow: hidden;
  margin-top: 10px;
}
.logo-new img {
  width: 100%;
}
.main-logo-new {
  width: 160px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 40px;
}
.nav-line {
  width: 1px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
}
.nav-title {
  display: inline-block;
  vertical-align: middle !important;
  background: url(../images/sub-logo.png) no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  width: 88px;
  overflow: hidden;
  margin-top: 5px;
}
.nav-title a {
  display: block;
  line-height: 18px;
  text-indent: -5000px;
}
.main-nav {
  font-size: 17px;
  z-index: 999999;
  position: absolute;
  right: 0px;
  top: 20px;
}
.main-nav a {
  color: #a17d59;
}
.main-nav li {
  display: inline-block;
  margin-right: 20px;
  height: 2em;
}
.main-nav li.link-social {
  margin-right: 3px;
  font-size: 25px;
}
.main-nav li:last-child {
  margin-right: 0;
}
.menu-btn {
  display: none;
}
.nav-menu {
  display: block;
}
.line a {
  color: #42c356 !important;
}
.facebook a {
  color: #2e64c9 !important;
}
li.link-product {
  position: relative;
  padding-right: 25px;
}
li.link-product:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #a17d59 transparent transparent transparent;
  vertical-align: middle;
  margin-left: 5px;
  position: absolute;
  right: 5px;
  top: 8px;
}
li.link-product:hover .link-submenu {
  display: block;
}
.link-submenu {
  position: absolute;
  right: 0;
  top: 100%;
  display: none;
  width: 580px;
  background: #fff;
  z-index: 9999;
  overflow: hidden;
  padding: 25px 0;
}
.sublink-group {
  width: 33.33%;
  box-sizing: border-box;
  float: left;
  padding: 0 20px;
}
.sublink-group:nth-child(2) {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}
.sublink-group a {
  display: block;
  font-size: 14px;
  color: #666;
  line-height: 2em;
  padding: 0 0 7px 0;
}
.sublink-group a:nth-child(1) {
  font-size: 16px;
  color: #a17d59;
}
.sublink-group a:hover {
  color: #b65138;
}
.mobile-social {
  display: none;
}
/*å…§å®¹*/
/*é¦–é */
.main-wrap {
  position: relative;
  padding-top: 65px;
}
.hint {
  position: absolute;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 3px 8px;
  box-sizing: border-box;
  font-size: 0.9em;
  z-index: 8888;
}
.hint a {
  color: #fff;
}
.hint a.close-btn {
  position: absolute;
  right: 0;
  top: 0;
}
.banner {
  max-width: 1660px;
  margin: auto;
}
.banner img {
  width: 100%;
}
.main-function {
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 25px;
}
.mf {
  border: 1px solid #a17d58;
  margin-bottom: 25px;
  border-radius: 30px;
  overflow: hidden;
}
.mf a {
  text-decoration: none;
}
.mf:hover .mask-dark {
  height: 100%;
}
.mf:hover .goto {
  display: block;
}
.mf h2 {
  text-align: center;
  color: #fff;
  background: #a17d58;
  font-size: 1.68em;
  padding: 0.45em 0;
  margin-bottom: 0;
}
.mf a {
  display: block;
}
.mf-inner {
  position: relative;
  overflow: hidden;
}
.img-wrap img {
  display: block;
}
.mf .img-wrap img {
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 270px;
}
.mask-dark {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 80px;
  left: 0;
  bottom: 0;
}
.mf-txt {
  color: #fff;
  z-index: 999;
  position: absolute;
  width: 100%;
  height: 80px;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 15px 0px 11px 15px;
  font-size: 0.9em;
  line-height: 1.5em;
}
.goto {
  display: none;
  width: 166px;
  height: 45px;
  position: absolute;
  left: 50%;
  margin-left: -83px;
  top: 80px;
  border: 2px solid #cfa85c;
  border-radius: 22px;
  color: #cfa85c;
  text-align: center;
  line-height: 40px;
  font-size: 1.3em;
}
.goto .fas {
  margin-left: 10px;
}
.product-block {
  overflow: hidden;
  box-shadow: #ccc 2px 2px 10px;
  position: relative;
  margin-bottom: 40px;
}
.product-block a {
  display: block;
}
.product-block .img-wrap {
  position: relative;
  width: 500px;
  float: left;
}
.product-block .img-wrap img {
  width: 100%;
}
.product-block:nth-child(2) .img-wrap {
  float: right;
}
.img-txt {
  color: #fff;
  position: absolute;
  bottom: 0;
  padding: 0 30px 25px 30px;
  box-sizing: border-box;
  width: 100%;
}
.img-txt div {
  margin-top: 15px;
}
.product-name {
  font-size: 0.7em;
  background: rgba(161, 125, 89, 0.8);
  display: inline-block;
  padding: 3px 2em;
}
.product-title {
  font-size: 1.68em;
}
.product-slogan {
  font-size: 0.9em;
}
.product-info .product-block:nth-child(2) .img-txt {
  text-align: right;
}
.product-txt-block {
  padding: 45px;
  float: left;
  position: relative;
}
.product-txt-block h2 {
  font-size: 1.68em;
  margin-bottom: 1.2em;
  color: #a17d59;
}
.product-txt {
  color: #707070;
  line-height: 1.8em;
}
.more-info {
  background: #a17d59;
  color: #fff;
  font-size: 18px;
  width: 144px;
  border-radius: 20px;
  text-align: center;
  padding: 0.3em 0;
  position: absolute;
  right: 40px;
  bottom: 40px;
}
.product-block:nth-child(2) .more-info {
  right: 540px;
}
.more-info .fas {
  margin-left: 10px;
}
.mobile-title {
  display: none;
}
/*è¡¨å°¾*/
footer {
  background: #2f2828;
  padding-top: 0;
}
.footer-wrap {
  text-align: center;
  padding-top: 10px;
  font-size: 15px;
}
.footer-wrap ul {
  padding: 0;
  margin-bottom: 0px;
}
.footer-wrap ul li {
  display: inline-block;
  margin: 0 8px;
}
.footer-wrap ul li a {
  color: #e5e5e5;
}
.businessInfo {
  border-top: 1px solid #707070;
  width: 80%;
  display: grid;
  color: #bababa;
  margin: 7px auto; 
  padding: 0;
}
.mobile-fuction {
  position: fixed;
  right: 35px;
  bottom: 100px;
  z-index: 9999;
}
.mfgroup a {
  display: block;
  width: 70px;
  height: 70px;
  text-align: center;
  border-radius: 35px;
  padding: 4px 0;
  font-size: 12px;
  color: #fff;
  margin: 0 0 10px 0;
}
a.mficon-01 {
  background: url(../images/mficon-w01.png) #a17d59;
  -webkit-background-size: 34px;
  background-size: 34px;
  background-position: center 9px;
  background-repeat: no-repeat;
}
a.mficon-02 {
  background: url(../images/mficon-w02.png) #a17d59;
  -webkit-background-size: 34px;
  background-size: 34px;
  background-position: center 9px;
  background-repeat: no-repeat;
}
a.mficon-03 {
  background: url(../images/mficon-w03.png) #a17d59;
  -webkit-background-size: 34px;
  background-size: 34px;
  background-position: center 9px;
  background-repeat: no-repeat;
}
.mfgroup span {
  display: block;
  margin-top: 32px;
}
a.scroll-to-top {
  color: #fff;
  background: #a17d59;
  width: 50px;
  height: 50px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  border-radius: 5px;
  text-align: center;
  line-height: 50px;
  z-index: 9999;
}
/*å°ˆäººè¯ç¹«*/

.contact {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}
.contact-form {
  width: 760px;
  position: absolute;
  left: 50%;
  margin-left: -380px;
  top: 90px;
  background: #fff;
  padding: 2px;
}
.contact-form a.close-btn {
  position: absolute;
  right: -10px;
  top: -10px;
  background: #fff;
  z-index: 9999;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 20px;
  text-align: center;
  color: #a17d58;
}
.contact-form a.close-btn .fa-times-circle {
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #000;
}
.contact-form .container {
  width: 100%;
  padding: 45px 35px 10px 35px;
}
.contact-form a {
  text-decoration: underline;
}
.title-contact {
  background: url(../images/title-contact.png);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  height: 120px;
  text-align: center;
  position: relative;
}
.title-contact:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 777;
}
.title-contact h2 {
  width: 100%;
  font-size: 1.57em;
  color: #fff;
  line-height: 120px;
  position: absolute;
  z-index: 888;
}
.contact a {
  color: #a17d59;
}
.form-row {
  margin-bottom: 30px;
  position: relative;
}
.form-row input[type="text"] {
  border: 1px solid #a17d59;
  width: 100%;
  height: 35px;
  line-height: 35px;
  padding: 10px;
}
.form-row input[type="text"].w2 {
  width: calc(100% - 45px);
  margin-right: 10px;
}
.form-row input[type="text"] + label {
  color: #d0beac;
  position: absolute;
  left: 10px;
  top: 4px;
}
.form-row input[type="text"]:focus + label,
.form-row input[type="text"] + label.not-empty {
  left: 0;
  top: -25px;
}
.form-row select {
  width: 100%;
  height: 35px;
  padding: 0 10px;
}
.form-row select.half {
  width: 49%;
  float: left;
  margin-bottom: 10px;
  margin-right: 1%;
}
.form-row select.half:nth-child(2) {
  margin-right: 0;
  margin-left: 1%;
}
.form-row select.onethird {
  width: 32%;
}
.form-row select.onethird:nth-child(3) {
  margin-right: 2%;
  margin-left: 2%;
}
.form-row {
  font-size: 15px;
}
.form-row h4 {
  font-weight: bold;
  margin-bottom: 10px;
  width: 100%;
  font-size: 15px;
}
.s-row {
  width: 100%;
  font-size: 15px;
}
.mmfix {
  margin-bottom: 35px;
}
.s-row.mmfix .btn {
  border-radius: 20px;
  padding-left: 2em;
  padding-right: 2em;
  margin: 0 0.7em;
}
.btn-clear {
  border: 2px solid #a17d59;
  color: #a17d59;
}
.form-row label {
  font-weight: normal;
}
.form-row input[type="radio"] {
  display: none;
}
.form-row input[type="radio"] + label span {
  display: block;
  padding-left: 25px;
  position: relative;
  line-height: 20px;
}
.form-row input[type="radio"] + label span:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 15px;
  border: 1px solid #a17d59;
}
.form-row input[type="radio"]:checked + label span:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 5px;
  top: 5px;
  border-radius: 15px;
  background: #000;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #d0beac;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d0beac;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d0beac;
}
select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #d0beac;
  border: 1px solid #a17d59;
  border-radius: 0;
  background: url("../images/select-icon.png") no-repeat scroll 98% center
    transparent;
  padding-right: 14px;
}
select::-ms-expand {
  display: none;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
/*å…§å®¹æ¨™é¡Œï¼Œå…§å®¹*/
.title-inner {
  height: 250px;
  text-align: center;
  position: relative;
}
.title-inner:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 777;
}
.title-inner h2 {
  line-height: 250px;
  color: #fff;
  font-size: 30px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 888;
}
.title-trial-calculation {
  background: url(../images/title-trial-calculation.png);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}
.title-house-valuation {
  background: url(../images/title-house-valuation.png);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}
.title-price-check {
  background: url(../images/title-price-check.png);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}
.title-quota-count {
  background: url(../images/title-quota-count.png);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}
.inner-content {
  font-size: 17px;
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 60vh;
}
.red {
  color: #b65138;
}
/*è¡¨æ ¼*/
.table-block {
  padding-top: 10px;
  padding-bottom: 90px;
  width: 100%;
}
.table-block table {
  width: 100%;
  font-size: 20px;
  border-collapse: collapse;
  border: 2px solid #a17d59;
}
.table-block table th {
  background: #a17d59;
  font-weight: normal;
  color: #fff;
  text-align: center;
  border-right: 2px solid #ffffff;
  padding: 5px;
}
.table-block table th:first-child {
  border-left: 2px solid #a17d59;
}
.table-block table th:last-child {
  border-right: 2px solid #a17d59;
}
.table-block table th.subth {
  background: #f8f6f3;
  font-size: 13px;
  line-height: 18px;
  color: #a17d59;
  border: 2px solid #a17d59;
}
.table-block table td {
  text-align: center;
  border: 2px solid #a17d59;
  padding: 5px;
}
.table-block table td:nth-child(3) {
  text-align: left;
  padding-left: 30px;
}
.table-row {
  padding: 8px;
  position: relative;
}
.ti-wrap {
  overflow: hidden;
  height: 115px;
}
.table-row.open .ti-wrap {
  height: auto;
}
.table-row.open {
  border: 1px solid #a17d59;
  height: auto;
  margin-bottom: 52px;
}
.table-row {
  margin-bottom: 30px;
}
.table-row table {
  margin-bottom: 12px;
  border-width: 1px;
  font-size: 15px;
}
.table-block .table-row table th {
  border-right: none;
  border-width: 1px;
}
.table-block .table-row table td {
  border-left: none;
  border-width: 1px;
}
.table-row table:first-child tr:first-child th {
  border-bottom: 2px solid #fff;
}
.table-row table:first-child tr:nth-child(2) th {
  border-bottom: 2px solid #fff;
}
a.table-btn {
  display: block;
  width: 50px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  background: #a17d59;
  text-align: center;
  position: absolute;
}
.table-close {
  right: 8px;
  bottom: -20px;
}
.table-open {
  right: 8px;
  bottom: -10px;
}
.mobile-table {
  display: none;
}
.count-fuction {
  text-align: center;
  background: #f8f6f3;
  padding: 70px 0;
}
.count-fuction a {
  display: inline-block;
  margin: 0 82px;
}
.count-fuction img {
  width: 85px;
  margin-bottom: 7px;
}
.count-fuction span,
.count-fuction strong {
  display: block;
  font-weight: normal;
}
.count-fuction span {
  font-size: 15px;
  color: #fff;
  background: #a17d59;
  border-radius: 20px;
  width: 130px;
  margin: auto;
  padding: 7px 0;
  margin-bottom: 7px;
}
.count-fuction strong {
  font-size: 12px;
  color: #000;
}
/*è¨ˆç®—ç›¸é—œé é¢*/

.h4 {
  color: #433c2a;
  font-size: 1.25rem;
  text-align: left;
  margin-bottom: 10px;
  position: relative;
  letter-spacing: 1px;
}
.nbtn-myAddress {
  color: #fff;
  background: #b65138;
  border-radius: 5rem;
  padding: 9px 17px;
  margin-right: 15px;
  margin-bottom: 19px;
  letter-spacing: 0.5px;
  min-width: 145px;
  border: none;
  font-size: 17px;
  line-height: 17px;
  float: left;
}
select.form-control {
  font-size: 16px;
  background: url(../images/arrow-down.png) #fff no-repeat;
  background-position: 96%;
  background-size: 9px;
  line-height: 26px !important;
  color: #888;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
}
.form-control {
  display: block;
  width: 100%;
  height: 42px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
select {
  word-wrap: normal;
}
.dropdown.mm {
  width: 145px;
  float: left;
  margin-right: 15px;
}
.addr-padding {
  padding-left: 520px;
}
.input-width {
  width: 100%;
}
.ng-search-more {
  background: #fffbee;
  padding: 30px;
  margin: 0 0 40px;
  border-radius: 0.55rem;
  clear: both;
  overflow: hidden;
}
.h4 span {
  color: #333;
  font-weight: 200;
  position: absolute;
  top: 50%;
  right: 0;
}
.f-xs {
  font-size: 12px;
}
.ng-form.nbrown label {
  color: #a17d58;
  font-size: 17px;
}
.nbrown {
  color: #a17d58;
}
.ng-form .row.row-fix {
  margin-top: 30px;
  margin-bottom: 30px;
}
.radio-row-group .col-md-12 {
  margin-bottom: 30px;
}
/*å¤šé¸æ¡†æ¨£å¼*/
.abgne-menu input[type="radio"] {
  display: none;
}
.abgne-menu p {
  font-size: 14px;
  position: relative;
  top: 35%;
  transform: translateY(-50%);
  white-space: nowrap;
  display: block;
}
.abgne-menu svg {
  position: relative;
  top: 10px; /* transform: translateY(-20%); */
}
.abgne-menu input[type="radio"] + label {
  display: inline-block;
  background-color: #fff;
  cursor: pointer;
  padding: 5px 10px;
  width: 156px;
  height: 117px;
  border: 1px solid #e6e6e6;
  border-radius: 0.3rem;
  text-align: center;
  margin-right: 17.4px;
}
.abgne-menu input[type="radio"]:checked + label {
  background-color: #cca769;
  color: #fff;
}
.abgne-menu input[type="radio"]:checked + label svg #Fill-1 {
  fill: #fff;
}
.search-input {
  position: relative;
}
.search-input input {
  width: calc(100% - 48px);
  display: inline;
  margin-right: 10px;
}
.floor-group .search-input-wrap {
  width: calc(100% - 80px);
  display: inline-block;
  font-size: 1rem;
  vertical-align: middle;
}
.search-input span {
  color: #a17d58;
}
.search-input span.keyin-txt {
  color: #b72100;
  font-size: 12px;
  position: absolute;
  left: 0;
  top: 40px;
}
.empty-note {
  border: 1px solid #b72100;
  background-image: url(../images/empty-note.png);
  background-repeat: no-repeat;
  background-position: right 50%;
}
.ng-form input::-moz-placeholder,
.ng-form textarea::-moz-placeholder {
  color: #999;
}
.ng-form input:-moz-placeholder,
.ng-form textarea:-moz-placeholder {
  color: #999;
}
.ng-form input:-ms-input-placeholder,
.ng-form textarea:-ms-input-placeholder {
  color: #999;
}
.floor-group > div:nth-of-type(1) {
  margin-right: 10px;
  max-width: 261px;
}
.floor-group > div {
  display: inline-block;
  vertical-align: middle;
}
label.setblock {
  display: block;
}
.park-group .el-radio {
  line-height: 40px;
}
.park-group div {
  display: inline-block;
  margin-right: 10px;
}
.btn-primary {
  color: #fff;
  background-color: #a17d58;
  border-color: #a17d58;
}
.btn-primary:hover {
  border-color: #a17d58;
}
.hr {
  border-top: 1px dashed #ceced2;
  margin-bottom: 35px;
}
.item-group .threeitem .h5 {
  margin-bottom: 30px;
}
.h5.line:before {
  display: none;
}
.f-sm {
  color: #555;
  font-size: 14px;
}
.big {
  font-size: 40px;
}
.g-map iframe {
  width: 100%;
  height: 600px;
}
.map-new .nav .btn {
  border: none;
}
.ng-form.disable {
  opacity: 0.5;
  pointer-events: none;
}
.g-code input {
  max-width: 230px;
  margin-bottom: 0;
  display: inline-block;
}
.mfx .item .h5 {
}
.mfx .item {
  padding: 20px 0;
}
.small {
  font-size: 12px;
  color: #696969;
}
.g-ccc {
  background: #f8f6f3;
  padding: 50px 0;
  text-align: left;
}
.bgf {
  background: #fff;
}
.count-area {
  padding-top: 15px;
}
.plus button {
  width: 42px;
  height: 42px;
  padding: 0;
}
.bootstrap-touchspin {
  width: calc(100% - 30px);
  float: left;
  margin-bottom: 55px;
}
.unit {
  line-height: 42px;
  padding-left: 5px;
}
.count-area.container {
  width: 100%;
}
.count-area {
  padding-bottom: 60px;
}
.count-area h6 {
  margin-bottom: 10px;
  clear: both;
}
.count-text {
  color: #696969;
  font-size: 14px;
  font-weight: bold;
}
button.bootstrap-touchspin-down {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
button.bootstrap-touchspin-up {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.recommend-some {
  padding: 30px 70px;
  overflow: hidden;
}
.recommend-some img {
  width: 100%;
  margin-bottom: 35px;
}
.recommend-some h4 {
  clear: both;
  font-size: 19px;
  padding: 0 0 30px 15px;
}
.recommend-some h6 {
  font-size: 17px;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}
.rs-txt {
  font-size: 14px;
  color: #696969;
  display: -webkit-box;
  overflow: hidden;
  /* -webkit-line-clamp: 6; */
  -webkit-box-orient: vertical;
  padding: 0 15px;
}
.rs-txt p {
  font-size: 14px;
  line-height: 1.3em;
}
.rs-txt strong {
  color: #000;
}
a.ritem {
  display: block;
  background: #fff;
  box-shadow: 2px 2px 10px #bbb;
  height: 540px;
  position: relative;
  margin: 0 15px;
  text-decoration: none;
}
.ritem span {
  display: block;
  width: 125px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  background: #a17d59;
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: calc(50% - 63px);
  border-radius: 20px;
}
.form-group.floor-group .col {
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
}
#valuation {
  width: 100%;
}
.read-protect-block.s-row {
  max-width: 520px;
  margin: 0 auto 40px auto;
}
.read-protect-title {
  text-align: left;
}
.read-protect {
  height: 100px;
  border: 1px solid #a17d59;
  text-align: left;
  padding: 5px;
  border-radius: 3px;
  overflow: auto;
  font-size: 12px;
  line-height: 1.6em;
  color: #a17d59;
}
.search-range {
  overflow: hidden;
  margin-top: -30px;
}
.a-range {
  float: left;
}
.a-range a {
  color: #a17d59;
  display: inline-block;
  margin-right: 15px;
}
.a-range a.current {
  color: #b65138;
  border-bottom: 2px solid #b65138;
}
.range-txt {
  float: right;
}
.range-txt img {
  width: 20px;
  vertical-align: middle;
  margin-right: 5px;
}
.range-txt span {
  vertical-align: middle;
}
/*è®€å–ä¸­*/
.loading-cover {
  position: fixed;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.loading-txt {
  background: #fff;
  color: #a17d59;
  text-align: center;
  max-width: 400px;
  height: 365px;
  padding: 80px 0;
  width: 60%;
  line-height: 80px;
  font-size: 30px;
  margin: 24vh auto 0 auto;
  text-align: center;
  box-sizing: border-box;
}
.loading-txt span {
  display: block;
}
.loading-txt img {
  margin-right: 10px;
}

/*RWDèª¿æ•´*/

@media screen and (max-width: 1199px) {
  .mask-dark {
    height: 95px;
  }
  .mf-txt {
    padding: 15px;
    height: 95px;
  }
  .goto {
    top: 60px;
  }
  .product-txt-block {
    padding: 30px;
  }
  .product-txt-block h2 {
    margin-bottom: 0.5em;
  }
  .product-txt {
    line-height: 1.6em;
  }
  a.ritem {
    margin: 0;
  }
  .recommend-some .col-lg-4 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 991px) {
  .logo-new {
    margin-top: 18px;
  }
  .main-logo-new {
    width: 110px;
    margin-right: 12px;
    margin-left: 25px;
  }
  .nav-line {
    margin-right: 10px;
  }
  .product-block .img-wrap {
    width: 410px;
  }
  .product-txt-block {
    padding: 18px 0 18px 18px;
  }
  .product-txt {
    font-size: 0.8em;
  }
  .more-info {
    bottom: 18px;
    right: 20px;
  }
  .product-block:nth-child(2) .more-info {
    right: 430px;
  }
  .main-function {
    padding-bottom: 0px;
    margin-bottom: -30px;
  }
  .title-inner {
    height: 200px;
  }
  .title-inner h2 {
    line-height: 200px;
  }
  .table-block table td:nth-child(2) {
    padding-left: 8px;
  }
  .count-fuction a {
    margin: 0 45px;
  }
  #valuation.result .item-group .item {
    display: block;
    width: auto;
  }
  .item-group .item.col-md-4 {
    display: block;
    width: auto;
  }
  .item-group .item.col-md-6 {
    display: block;
    width: auto;
  }
  a.ritem {
    height: auto;
    margin-bottom: 30px;
  }
  .ritem img {
    margin-bottom: 0;
  }
  .rwrap {
    position: relative;
  }
  .rwrap:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.8) 22%,
      rgba(0, 0, 0, 0.2) 60%
    );
    background: -moz-linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.8) 22%,
      rgba(0, 0, 0, 0.2) 60%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.8) 22%,
      rgba(0, 0, 0, 0.2) 60%
    );
    background: -o-linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.8) 22%,
      rgba(0, 0, 0, 0.2) 60%
    );
    left: 0;
    top: 0;
  }
  .ritem h6 {
    position: absolute;
    left: 20px;
    bottom: 45px;
    color: #fff;
    font-size: 25px;
  }
  .ritem h6:after {
    display: none;
  }
  .rs-txt {
    display: none;
  }
  .ritem span {
    color: #cfa85c;
    background: none;
    border: 2px solid #cfa85c;
    bottom: 20px;
    left: 20px;
    border-radius: 20px;
  }
  .abgne-menu {
    overflow: hidden;
  }
  .abgne-menu input[type="radio"] + label {
    width: 32%;
    display: block;
    float: left;
    margin-right: 1.3%;
  }
  .mobile-title {
    display: block;
    text-align: center;
    font-size: 20px;
    color: #cfa85c;
    margin: 20px 0;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .product-block {
    position: relative;
  }
  .product-block .img-wrap {
    width: 100%;
  }
  .product-block .img-wrap img {
    width: 100%;
  }
  .product-txt-block {
    float: none;
    padding: 0;
    position: absolute;
    width: 100%;
    top: 0;
  }
  .product-txt-block h2,
  .product-txt {
    display: none;
  }
  .product-info .product-block:nth-child(2) .img-txt {
    text-align: left;
  }
  .more-info {
    right: auto;
    left: 25px;
    background: #a17d59;
    color: #fff;
    /* border: 2px solid #cfa85c; */
    bottom: 28px;
  }
  .product-block:nth-child(2) .more-info {
  }
  .mf .img-wrap img {
    height: 180px;
  }
  .goto {
    top: 40px;
  }
  .mf-txt {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 60px;
    margin-bottom: 10px;
  }
  .img-txt {
    padding-bottom: 80px;
  }
  .table-block table th.subth br {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .slider.desktop {
    display: none;
  }
  .bx-controls-direction {
    display: none;
  }
  .bx-wrapper {
    margin-bottom: 0;
  }
  .slider-mobile {
    display: block;
  }
  .top-bar,
  .main-nav {
    position: static;
  }
  .nav-menu {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background: #f8f6f3;
    height: 100vh;
    padding-left: 0;
  }
  .nav-menu-open {
    display: block;
  }
  .main-nav li {
    display: block;
    margin-right: 0px;
    height: auto;
  }
  .nav-menu li {
    padding: 15px 30px;
  }
  .nav-menu li a {
    color: #666;
  }
  li.link-product:after {
    border-color: #666 transparent transparent transparent;
    right: 15px;
    top: 22px;
  }
  .nav-menu li {
    border-bottom: 1px solid #dfdcdc;
  }
  .link-submenu {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    display: block;
    z-index: auto;
    background: none;
  }
  .sublink-group {
    float: none;
    width: auto;
  }
  .sublink-group:nth-child(2) {
    border-left: none;
    border-right: none;
  }
  .nav-menu li.link-social {
    border-bottom: none;
    display: inline-block;
    margin-right: 0px;
    padding: 5px 0px 0 10px;
    font-size: 30px;
    display: none;
  }
  .nav-menu li.link-social.facebook {
    padding-left: 30px;
  }
  a.menu-btn {
    display: block;
    position: absolute;
    right: 13vw;
    top: 18px;
    color: #a17d58;
  }
  .mobile-social {
    display: block;
    position: absolute;
    right: 17vw;
    top: 18px;
    color: #a17d58;
    font-size: 1.5em;
  }
  .hint {
    /* display: none; */
  }
  .mask-dark {
    height: 70px;
  }
  .mf-txt {
  }
  .mf .img-wrap img {
    height: 220px;
    object-position: 50% 25%;
  }
  .contact-form {
    width: 100%;
    position: absolute;
    left: 0;
    margin-left: 0;
    top: 65px;
    padding: 0;
  }
  .contact-form a.close-btn {
    right: 5px;
    top: 5px;
  }
  .all-wrap {
    padding-bottom: 165px;
  }
  .mobile-fuction {
    display: block;
    background: #f7f1df;
    bottom: 95px;
    right: auto;
    left: 0;
    width: 100vw;
    padding: 20px 0;
    margin-left: 0;
    z-index: 999;
  }
  .footer-wrap {
    width: 100vw;
    background-color: #2f2828;
    height: 95px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    padding-top: 4px;
    font-size: 12px;
  }
  .footer-wrap ul {
    margin-bottom: 1px;
  }
  .businessInfo{
    padding: 0;
    margin-top: 1px;
  }
  .businessInfo span {
	line-height:20px;
  }
  .mfgroup {
    text-align: center;
    margin: -10px 0;
  }
  .mfgroup a {
    display: inline-block;
    width: 90px;
    height: 50px;
    border: 1px solid #a17d59;
    text-align: center;
    border-radius: 10px;
    padding: 4px 0;
    margin: 0 5px;
    font-size: 12px;
    color: #a17d59;
  }
  a.mficon-01 {
    background: url(../images/mficon-01.png) #fff;
    -webkit-background-size: 29px 25px;
    background-size: 29px 25px;
    background-position: center 5px;
    background-repeat: no-repeat;
  }
  a.mficon-02 {
    background: url(../images/mficon-02.png) #fff;
    -webkit-background-size: 29px 25px;
    background-size: 29px 25px;
    background-position: center 5px;
    background-repeat: no-repeat;
  }
  a.mficon-03 {
    background: url(../images/mficon-03.png) #fff;
    -webkit-background-size: 29px 25px;
    background-size: 29px 25px;
    background-position: center 4px;
    background-repeat: no-repeat;
  }
  .mfgroup span {
    display: block;
    margin-top: 22px;
  }
  .container.inner-content {
    padding-left: 2%;
    padding-right: 2%;
  }
  .title-inner {
    height: 150px;
  }
  .title-inner h2 {
    line-height: 150px;
    font-size: 20px;
  }
  .desktop-table {
    display: none;
  }
  .mobile-table {
    display: block;
    font-size: 15px;
    margin-bottom: 50px;
  }
  .mobile-table.table-block {
    padding-bottom: 0px;
  }
  .count-fuction {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .count-fuction a {
    margin: 0 5px;
  }
  .count-fuction img {
    width: 50px;
    margin-bottom: 7px;
  }
  .count-fuction strong {
    display: none;
  }
  .count-fuction span {
    font-size: 12px;
    width: 95px;
  }
  .nbtn-myAddress {
    width: 100%;
  }
  .dropdown.mm {
    box-sizing: border-box;
    width: 50%;
    margin-right: 0;
    padding-bottom: 20px;
  }
  .dropdown.mm:nth-child(1) {
    padding-right: 5px;
  }
  .dropdown.mm:nth-child(2) {
    padding-left: 5px;
  }
  .addr-padding.input-width {
    padding-left: 0px;
    overflow: hidden;
    width: 100% !important;
  }
  .abgne-menu input[type="radio"] + label {
    width: 48%;
    margin: 5px 1%;
  }
  .imgnumber {
    max-width: 240px;
  }
  .a-range {
    float: none;
    margin-bottom: 10px;
  }
  .range-txt {
    float: none;
  }
  .search-range {
    margin-bottom: 70px;
  }
  .bx-wrapper .bx-controls-direction a {
    width: 20px;
    height: 30px;
    margin-top: -15px;
  }
  .goto {
    top: 60px;
  }
  a.scroll-to-top {
    right: 5px;
    bottom: 5px;
  }
  .loading-txt {
    font-size: 20px;
    height: 230px;
    padding: 30px 0;
    max-width: 230px;
    width: 50%;
    line-height: 50px;
  }
  .loading-txt img {
    margin-right: 0px;
    width: 120px;
  }
}

@media screen and (max-width: 590px) {
  .calculation-content {
    padding: 0 5%;
  }
}

@media screen and (max-width: 550px) {
  .nav-title {
    width: 70px;
    height: 15px;
    margin-top: 7px;
  }
  .main-logo-new {
    margin-right: 6px;
  }
  .nav-line {
    margin-right: 5px;
  }
  a.menu-btn {
    right: 6vw;
  }
  .mobile-social {
    right: 12vw;
  }
}

@media screen and (max-width: 468px) {
  .title-inner {
    height: 78px;
  }
  .title-inner h2 {
    line-height: 78px;
    font-size: 14px;
  }
  .form-row select.onethird {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .form-row select.onethird:nth-child(3) {
    margin-left: 0;
    margin-right: 0;
  }
  .table-row table {
    font-size: 13px;
  }

  .recommend-some {
    padding: 10px;
    overflow: hidden;
  }
}

@media screen and (max-width: 370px) {
  .all-wrap {
    padding-bottom: 180px;
  }
  .mobile-fuction {
    bottom: 115px;
  }
  .footer-wrap {
    height: 115px;
  }
}

/*200522 Chris*/
@media (min-width: 991px) {
  .mobile {
    display: none;
  }
}

@media (max-width: 991px) {
  .mobile {
    display: block;
  }
}
.ritem .mobile {
  position: absolute;
  right: 20px;
  color: white;
  bottom: 20px;
}
.ritem em.txtR {
  color: red;
  border: none;
  /*position: unset;*/
  width: auto;
  height: auto;
  /*line-height: unset;*/
  /*display: initial;*/
  font-size: 130%;
  font-style: normal;
}
